import React from "react";

class CometChatNotLogin extends React.Component {
  
  render() {

    return (
      <div>
          <h2>You are not login</h2>
      </div>
    );
  }
}

export default CometChatNotLogin;