export const callMessageStyle = () => {

    return {
        padding: "8px 12px",
        marginBottom: "16px",
        textAlign: "center",
    }
}

export const callMessageTxtStyle = () => {

    return {
        fontSize: "13.5px",
        margin: "0",
    }
}