import React from "react";
/** @jsx jsx */
import { jsx } from '@emotion/core'

import { CometChat } from "@cometchat-pro/chat";
import Avatar from "../Avatar";

import BadgeCount from "../BadgeCount";
import StatusIndicator from "../StatusIndicator";

import { listItem, itemLastMsgStyle, itemRowStyle, itemLastMsgTimeStyle, itemThumbnailStyle, itemDetailStyle, itemNameStyle, itemDescStyle } from "./style";

const userview = (props) => {
      let state = {
        lastMessage: "",
        lastMessageTimestamp: ""
      }

      let componentDidMount = () => {
      // componentDidMount() {

        const message = this.getLastMessage();
        const timestamp = this.getLastMessageTimestamp();
    
        this.setState({ lastMessage: message, lastMessageTimestamp: timestamp });
      }

      let componentDidUpdate = (prevProps) => {
      // componentDidUpdate(prevProps) {
    
        const previousItem = JSON.stringify(prevProps.conversation);
        const currentItem = JSON.stringify(this.props.conversation);
    
        if (previousItem !== currentItem) {
    
          const message = this.getLastMessage();
          const timestamp = this.getLastMessageTimestamp();
    
          this.setState({ lastMessage: message, lastMessageTimestamp: timestamp });
        }
      }
    
      let getLastMessage = () => {
    
        if (this.props.hasOwnProperty("conversation") === false) {
          return false;
        }
    
        if (this.props.conversation.hasOwnProperty("lastMessage") === false) {
          return false;
        }
    
        let message = null;
        const lastMessage = this.props.conversation.lastMessage;
    
        if (lastMessage.hasOwnProperty("deletedAt")) {
    
          message = (this.props.loggedInUser.uid === lastMessage.sender.uid) ? "⚠ You deleted this message." : "⚠ This message was deleted.";
    
        } else {
    
          switch (lastMessage.category) {
            case "message":
              message = this.getMessage(lastMessage);
              break;
            case "call":
              message = this.getCallMessage(lastMessage);
              break;
            case "action":
              message = lastMessage.message;
              break;
            case "custom":
              message = this.getCustomMessage(lastMessage);
              break;
            default:
              break;
          }
        }
    
        return message;
      }
    
      let getLastMessageTimestamp = () => {
    
        if (this.props.hasOwnProperty("conversation") === false) {
          return false;
        }
    
        if (this.props.conversation.hasOwnProperty("lastMessage") === false) {
          return false;
        }
    
        if (this.props.conversation.lastMessage.hasOwnProperty("sentAt") === false) {
          return false;
        }
    
        let timestamp = null;
    
        const messageTimestamp = new Date(this.props.conversation.lastMessage.sentAt * 1000);
        const currentTimestamp = Date.now();
    
        const diffTimestamp = currentTimestamp - messageTimestamp;
    
        if (diffTimestamp < 24 * 60 * 60 * 1000) {
    
          timestamp = messageTimestamp.toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true });
    
        } else if (diffTimestamp < 48 * 60 * 60 * 1000) {
    
          timestamp = "Yesterday";
    
        } else if (diffTimestamp < 7 * 24 * 60 * 60 * 1000) {
    
          timestamp = messageTimestamp.toLocaleString('en-US', { weekday: 'long' });
    
        } else {
    
          timestamp = messageTimestamp.toLocaleDateString('en-US', { year: "2-digit", month: "2-digit", day: "2-digit" });
        }
    
        return timestamp;
      }
    
      // let getCustomMessage = (lastMessage) => {
    
      //   let message = null;
      //   switch(lastMessage.type) {
      //     case enums.CUSTOM_TYPE_POLL:
      //       message = "Poll";
      //       break;
      //     case enums.CUSTOM_TYPE_STICKER:
      //       message = "Sticker";
      //       break;
      //     default:
      //       break;
      //   }
    
      //   return message;
      // }
    
      let getMessage = (lastMessage) => {
    
        let message = null;
        switch (lastMessage.type) {
    
          case CometChat.MESSAGE_TYPE.TEXT:
            message = lastMessage.text;
            break;
          case CometChat.MESSAGE_TYPE.MEDIA:
            message = "Media message";
            break;
          case CometChat.MESSAGE_TYPE.IMAGE:
            message = "📷 Image ";
            break
          case CometChat.MESSAGE_TYPE.FILE:
            message = "📁 File";
            break;
          case CometChat.MESSAGE_TYPE.VIDEO:
            message = "🎥 Video";
            break;
          case CometChat.MESSAGE_TYPE.AUDIO:
            message = "🎵 Audio";
            break;
          case CometChat.MESSAGE_TYPE.CUSTOM:
            message = "Custom message";
            break;
          default:
            break;
        }
    
        return message;
      }
    
      let getCallMessage = (lastMessage) => {
    
        let message = null;
        switch (lastMessage.type) {
          case CometChat.MESSAGE_TYPE.VIDEO:
            message = "Video call";
            break;
          case CometChat.MESSAGE_TYPE.AUDIO:
            message = "Audio call";
            break;
          default:
            break;
        }
    
        return message;
      }
      let getAvatar = () => {

        let avatar;
        if (this.props.conversation.conversationType === "user") {
          avatar = this.props.conversation.conversationWith.avatar;
        } else if (this.props.conversation.conversationType === "group") {
          avatar = this.props.conversation.conversationWith.icon;
        }
        return avatar;
      }

      let lastMessageTimeStamp = null;
        if (state.lastMessage) {
          lastMessageTimeStamp = (
            <span css={itemLastMsgTimeStyle(this.props)} className="item__details__timestamp">{this.state.lastMessageTimestamp}</span>
          );
        }

      let userPresence = (
        <StatusIndicator
        widgetsettings={props.widgetsettings}
        status={props.user.status}
        cornerRadius="50%" 
        borderColor={props.theme.color.darkSecondary}
        borderWidth="1px" />
      );

      const toggleTooltip =   (event, flag) => {

        const elem = event.target;

        const scrollWidth = elem.scrollWidth;
        const clientWidth = elem.clientWidth;

        if(scrollWidth <= clientWidth) {
          return false;
        }

        if(flag) {
          elem.setAttribute("title", elem.textContent);
        } else {
          elem.removeAttribute("title");
        }
      } 
      // console.log(props.user.status);
      return (
        <div css={listItem(props)} onClick={() => props.clickeHandler(props.user)}>
          <div css={itemThumbnailStyle()}>
            <Avatar 
            image={props.user.avatar} 
            cornerRadius="50%" 
            borderColor={props.theme.color.secondary}
            borderWidth="1px" />
            {userPresence}
          </div>
          <div css={itemDetailStyle()}>
          <div css={itemRowStyle()} className="item__details_block_one">
            <div css={itemNameStyle()} className="item__details__name"
              onMouseEnter={event => toggleTooltip(event, true)}
              onMouseLeave={event => toggleTooltip(event, false)}>{props.user.display_name}</div>
              {/* {lastMessageTimeStamp} */}
            </div>
            <div css={itemRowStyle()} className="item__details_block_two">
              <div css={itemLastMsgStyle(props)} className="item__details__last-message"
              onMouseEnter={event => toggleTooltip(event, true)}
              onMouseLeave={event => toggleTooltip(event, false)}>{state.lastMessage}</div>
              <BadgeCount theme={props.theme} count={props.user.unreadMessageCount} />
            </div> 
            {/* <div css={itemNameStyle()} 
            onMouseEnter={event => toggleTooltip(event, true)} 
            onMouseLeave={event => toggleTooltip(event, false)}>{props.user.display_name}</div>
            <div css={itemDescStyle(props.theme)}></div>
          </div> */}
        </div>
        </div>
        
      )
}

export default userview;